.service-item {
  box-shadow: 0px 0px 15px rgba(0,0,0,0.10);
  text-align: center;

  img {
    width: 100%;
    overflow: hidden;
  }

  p {
    margin-bottom: 25px;
  }
}

.card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0rem;

  .card-header,
  .card-body,
  .card-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
  }

  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 0 solid transparent;
  
    &:first-child {
      border-radius: 0rem 0rem 0 0;
    }
  }
    
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: transparent;
    border-top: 0 solid transparent;
  
    &:last-child {
      border-radius: 0 0 0rem 0rem;
    }   
    
    button {
      padding: 0.25rem 0.5rem;
      font-size: 0.9rem; 
      line-height: 1.5;
      width: fit-content;
    }
  }

  .card-body {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 1.25rem;
    margin: auto;
  
    .card-title {
      margin-bottom: 0.75rem;
    }
  
    p {
      padding: 1rem;
    }
  
  }
}
  
  






  