.courses h2 {
  font-size: 1.8em;
  margin: 20px 0;
}

.dropdown {
  margin: 10px 0;
  text-align: left;
}

.dropdown-btn {
  width: 100%;
  max-width: 600px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
  text-align: left;
  margin: 5px auto;
}

.dropdown-content {
  display: none;
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  max-width: 600px;
  margin: 0 auto;
}

.dropdown .dropdown-content {
  display: block;
}
