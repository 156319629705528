.profile-services h2 {
  font-size: 1.8em;
  margin: 20px 0;
}

.main-service {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-item {
  width: 250px;
  text-align: center;
}

.service-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.service-item p {
  font-size: 1em;
  color: #333;
  margin: 10px 0;
}
