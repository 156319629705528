.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.event-container {
    margin-top: 3rem;

    h2 {
        margin-bottom: 1rem;
    }

    p {
        margin-top: 1rem;
    }

    h4 {
        margin-top: 1rem;
    }

    .image-center {
        text-align: center; 
        padding: 1rem;       
        border-radius: 0.5rem;   
    }

    .text-center {
        text-align: center;
        background-color: #f8f9fa; 
        padding: 1rem;       
        border-radius: 0.5rem;   
    }

}
