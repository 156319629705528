.hero {
    width: 100%;
    min-height: calc(100vh - 100px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 120px 0 60px 0;
    overflow: hidden;
  }
  
  .hero:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 30%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .hero img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .hero .container {
    position: relative;
    z-index: 3;
  }
  
  .hero h1 {
    margin: 0;
    font-size: 56px;
    font-weight: 700;
    line-height: 72px;
  }
  
  .hero p {
    margin: 10px 0 0 0;
    font-size: 22px;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  @media (max-width: 992px) {
    .hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    .hero p {
      font-size: 18px;
      line-height: 24px;
    }
  }
  
  .hero .btn-get-started {
    color: var(--contrast-color);
    background: var(--accent-color);
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 14px 50px;
    border-radius: 5px;
    transition: 0.5s;
    margin-top: 30px;
  }

  .button {
    width: auto !important;
    padding: 0 4rem;
    line-height: 1.5;
    letter-spacing: 0.5px;
  }
  
  // .hero .btn-get-started:hover {
  //   background: color-mix(in srgb, var(--accent-color), transparent 15%);
  // }
  
  .hero .icon-box {
    padding: 50px 30px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
  
  .hero .icon-box .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .hero .icon-box .title a {
    color: var(--heading-color);
    transition: 0.3s;
  }
  
  .hero .icon-box .description {
    font-size: 15px;
    margin-bottom: 0;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .hero .icon-box .icon {
    margin-bottom: 20px;
    padding-top: 10px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 36px;
    line-height: 1;
    color: var(--accent-color);
  }
  
  @media (min-width: 640px) {
    .hero .icon-box:hover {
      transform: scale(1.08);
    }
  
    .hero .icon-box:hover .title a {
      color: var(--accent-color);
    }
  }