// input,
// textarea {
// width: 100%;
// padding: 8px;
// border-radius: 4px;
// border: 1px solid #ccc;
// }

.radio-group,
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    label {
        display: flex;
        margin-right: 20px;
        align-items: center;
        gap: 0.5rem;
        white-space: nowrap;
    }
}

.error {
    color: #ff0000;
    font-size: 13px;
    margin-bottom: 1rem;
}

.input-error {
    border-color: #fc8181;
}