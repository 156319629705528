.page-container {
    margin-top: 1.5rem;
    padding: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    
    .page-content {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
    }

}
  
 
  
  @media (min-width: 768px) {
    .page-container {
      -webkit-flex-wrap: nowrap !important;
          -ms-flex-wrap: nowrap !important;
              flex-wrap: nowrap !important;


        .page-content {
        width: calc(100% - 300px);
        padding: 0;
        }
    }
}