@media (max-width: 767.98px) {
    .page-header {
      max-height: 350px;
    }
  }
  
  .page-header:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.2);
  }
  
  .page-header.page-header-mini {
    height: 250px;
    background: #f8f9fa;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .page-header.page-header-mini .title {
    color: #343a40;
    font-size: calc(38px + (50 - 48) * ((100vw - 320px) / (1200 - 320)));
    z-index: 10;
    font-weight: bold;
    font-size: 29px;
  }
  
  .page-header.page-header-mini:after {
    display: none;
  }
  
  .header-mini {
    min-height: 24rem;
    height: 24rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    padding: 1rem;
    background: #d8f9f2 !important;
  }
  
  .header-mini .header-title {
    font-size: 2.4rem;
    font-weight: bold;
    opacity: .8;
    color: #212529;
  }
  
  .page-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  
  .page-container .page-content {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  
  
  @media (min-width: 768px) {
    .page-container {
      -webkit-flex-wrap: nowrap !important;
          -ms-flex-wrap: nowrap !important;
              flex-wrap: nowrap !important;
    }
    .page-container .page-content {
      width: calc(100% - 300px);
      padding: 0;
    }
  }