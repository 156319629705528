.profile-intro {
  h1 {
    font-size: 2em;
    margin: 10px 0;
  }

  p {
    font-size: 1.2em;
    color: #555;
    margin: 10px 0 20px;
  }

}
  