.admin-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 150px 20px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #673ab7;
  }

  .table-container {
    width: 100%;
    overflow-x: auto; 
  }

  table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    margin: 0 auto;

    th,
    td {
      padding: 10px;
      border: 1px solid #ddd;
      text-align: left;
    }

    th {
      background-color: #673ab7;
      color: white;
    }

    td {
      background-color: #fff;
      word-wrap: break-word; 
    }
  }
}

@media (max-width: 768px) {
  .admin-page {
    padding: 20px;
  }

  table th,
  table td {
    font-size: 0.9rem; 
    padding: 8px;
  }
}
