.form-input {
  width: 100%;
  height: 44px;
  line-height: 44px;
  padding: 0px 15px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 37, 41, 0.75);
  border-radius: 20px;
  border: 1px solid #7a7a7a;
  box-shadow: none;
  margin-bottom: 0.2rem;
  
  &:focus {
    border-color: #2a2a2a;
    border-radius: 20px;
  }
}

.input-error {
  border-color: #fc8181;
}
  
// input.input-error,
// select.input-error {
// border-color: #fc8181;
// }
  
